//facet_controller.js
// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  collapsedValue: Boolean
  collapseIconTarget: Element
  expandIconTarget: Element
  facetItemTargets: Element[]
  static values = { collapsed: Boolean }
  static targets = ["collapseIcon","expandIcon","facetItem"]
  initialize(): void {
    this.resetTargets()
  }
  resetTargets(): void {
    if(this.collapsedValue){
      this.expandIconTarget.classList.remove("hidden")
      this.collapseIconTarget.classList.add("hidden")
      this.facetItemTargets.forEach((x) => {
        x.classList.add("hidden")
      })
    } else {
      this.expandIconTarget.classList.add("hidden")
      this.collapseIconTarget.classList.remove("hidden")
      this.facetItemTargets.forEach((x) => {
        x.classList.remove("hidden")
      })
    }
  }
  toggle(): void {
    this.collapsedValue = !this.collapsedValue
    this.resetTargets()
  }

}
