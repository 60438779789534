//facet_controller.js
// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  collapsedValue: Boolean
  collapseIconTarget: Element
  expandIconTarget: Element
  collapseItemTarget: Element
  static values = { collapsed: Boolean }
  static targets = ["collapseIcon","expandIcon","collapseItem"]
  connect() {
  }
  initialize(): void {
    this.resetTargets()
  }
  toggleIcons(): void {
    if(this.collapsedValue){
      this.expandIconTarget.classList.remove("hidden")
      this.collapseIconTarget.classList.add("hidden")
    } else {
      this.expandIconTarget.classList.add("hidden")
      this.collapseIconTarget.classList.remove("hidden")
    }
  }
  resetTargets(): void {
    this.toggleIcons()
    if(this.collapsedValue){
      this.collapseItemTarget.classList.add("hidden")
    } else {
      this.collapseItemTarget.classList.remove("hidden")
    }
  }
  toggle(): void {
    this.collapsedValue = !this.collapsedValue
    this.resetTargets()
    console.log("Hello, Stimulus!", this)

  }

}
